import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { AuthenticationRedirectService } from '@app/core/authentication/authentication-redirect.service';
import { LegacyAuthenticationCompatibilityService } from '@app/core/authentication/legacy-authentication-compatibility.service';
import { InsCookieService, RouterHistoryService } from '@app/core/services';
import { AppConfigService } from '@inshared/shared/core';
import { DatalayerConfigInterface, DatalayerService, LabelEnum } from 'outshared-lib';
import { CynolyticsService } from './core/analytics/cynolytics.service';
import { WebVitalsService } from './core/analytics/web-vitals.service';
import { FeatureDetectionService } from './core/services/feature-detection.service';
import { SeoService } from './core/services/seo.service';

@Component({
    selector: 'ins-root',
    templateUrl: './app.component.html',
})
export class AppComponent {
    private datalayerConfig: DatalayerConfigInterface = {
        gtmId: this.appConfigService.getConfig()?.gtmId,
        label: LabelEnum.ISNL,
    };

    public hideBreadcrumb: string = 'hideBreadcrumb';
    public hideFooter: string = 'hideFooter';

    constructor(
        @Inject(PLATFORM_ID) platformId: Object,
        private routerHistory: RouterHistoryService,
        private cynolyticsService: CynolyticsService,
        private dataLayerService: DatalayerService,
        private webVitalsService: WebVitalsService,
        private featureDetectionService: FeatureDetectionService,
        private authenticationRedirectService: AuthenticationRedirectService,
        private legacyAuthenticationCompatibilityService: LegacyAuthenticationCompatibilityService,
        private cookieService: InsCookieService,
        // Can only use AppConfigService from libs if library version is initialized
        private appConfigService: AppConfigService,
        public seoService: SeoService
    ) {
        if (isPlatformBrowser(platformId)) {
            this.routerHistory.loadRouting();
            // Initalize services
            this.seoService.init();
            this.dataLayerService.init(this.datalayerConfig);
            this.cynolyticsService.init();
            this.webVitalsService.init();
            this.featureDetectionService.init();
            this.authenticationRedirectService.init();
            this.legacyAuthenticationCompatibilityService.init();
            this.cookieService.init();
        }
    }
}
