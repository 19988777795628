import { isPlatformBrowser } from '@angular/common';
import { ErrorHandler, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ErrorInterface, NotificationInterface } from 'outshared-lib';
import { StorageService } from '@app/core/storage/universal.inject';
import { NewRelicBrowser } from '../interfaces/new-relic.interface';

declare const newrelic: NewRelicBrowser;
declare global {
    interface Window {
        Cypress?: any;
    }
}

@Injectable({
    providedIn: 'root',
})
export class AppGlobalErrorhandler implements ErrorHandler {
    // eslint-disable-next-line no-useless-constructor
    constructor(@Inject(StorageService) private storage: Storage, @Inject(PLATFORM_ID) private platformId: object) {}

    // Added this to prevent private user data entering new relic logs - Remove when backend anonymized error responses
    private isIncorrectNotificationType(singleError: NotificationInterface): boolean {
        return singleError?.notification_type && singleError.notification_type === 'MEDE';
    }

    private throwError(error): void {
        console.error(error);

        if (
            this.isIncorrectNotificationType(error as NotificationInterface) ||
            !isPlatformBrowser(this.platformId) ||
            window.Cypress ||
            this.storage.getItem('ls.cookie.setting') !== 'compleet'
        ) {
            return;
        }

        newrelic.noticeError(error);
    }

    public handleError(error): void {
        // Handle ErrorInterface from outshared-lib
        if (error.length) {
            error.forEach((singleError: ErrorInterface | NotificationInterface): void => {
                this.throwError(singleError);
            });
            return;
        }

        // Default error handling
        this.throwError(error);
    }
}
