import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { StorageService } from '@app/core/storage/universal.inject';
import { environment } from '../../../environments/environment';

enum NewRelicUrlEnum {
    TestAndAcceptance = '/assets/scripts/new-relic-agent.js',
    Production = '/assets/scripts/new-relic-agent.prod.js',
}

@Injectable({
    providedIn: 'root',
})
export class NewRelicService {
    private renderer: Renderer2;

    constructor(
        private rendererFactory: RendererFactory2,
        @Inject(StorageService) private storage: Storage,
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: object
    ) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    public init(): void {
        if (isPlatformServer(this.platformId) || !this.hasPermissions() || this.hasNewRelicScript()) {
            return;
        }

        this.addScript();
    }

    public removeScript(): void {
        const element = this.getNewRelicScript();

        if (element === null) {
            return;
        }

        element.parentNode.removeChild(element);
    }

    private addScript(): void {
        const newRelicUrl = environment.production ? NewRelicUrlEnum.Production : NewRelicUrlEnum.TestAndAcceptance;
        const csa = this.renderer.createElement('script');

        this.renderer.setAttribute(csa, 'type', 'text/javascript');
        this.renderer.setAttribute(csa, 'src', newRelicUrl);
        this.renderer.setAttribute(csa, 'id', 'newRelicScript');

        this.document.getElementsByTagName('head')[0].appendChild(csa);
    }

    private getNewRelicScript(): HTMLElement {
        return this.document.getElementById('newRelicScript');
    }

    private hasNewRelicScript(): boolean {
        return Boolean(this.getNewRelicScript());
    }

    private hasPermissions(): boolean {
        // No cookie permission yet
        if (!this.storage.getItem('ls.cookie.setting')) {
            return false;
        }

        // User chose complete, allowing us to use this script
        return this.storage.getItem('ls.cookie.setting') === 'compleet';
    }
}
