import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { WINDOW } from '@inshared/shared/util';
import { NewModal } from 'outshared-lib';
import { StorageService } from '../../core/storage/universal.inject';
import { CookieSettingsWallModalComponent } from '../../shared/modals/cookie-settings-wall/cookie-settings-wall-modal.component';

/**
 * @deprecated replace with CookiebarContainer from '@inshared/shared/feature-cookie`
 */
@Component({
    selector: 'ins-cookiebar-container',
    template: '',
})
export class CookiebarContainer implements OnInit {
    constructor(
        @Inject(PLATFORM_ID) private platform: Object,
        @Inject(WINDOW) private window: Window,
        @Inject(StorageService) private storage: Storage,
        private newModal: NewModal
    ) {}

    ngOnInit(): void {
        // First check if the environment is a browser
        if (isPlatformBrowser(this.platform)) {
            this.initCookiebar();
        }
    }

    private initCookiebar(): void {
        // Check if a cookie is set before opening a the cookiewall && dont show cookie on opt-out page
        if (!this.storage.getItem('ls.cookie.setting') && !this.window.location.href.includes('opt-out')) {
            this.newModal.open<CookieSettingsWallModalComponent>(CookieSettingsWallModalComponent, {
                disableClose: true,
            });
        }
    }
}
